<script setup>    
    import { ref, watch, onBeforeMount } from 'vue';
    import Image from '@/components/Image.vue';
    import Ruler from '@/components/Ruler.vue';
    import FractionSelect from '@/components/FractionSelect.vue'
    import Fraction from '@/fraction.js';

    const props = defineProps(['step']);
    const emit = defineEmits(['toggle-ruler-lock', 'ruler-changed', 'ruler-adjustment-changed']);
    const rulerAdjustmentHelper = ref(false);
    
    const currentRulerAdjustment = ref(0)

    function onFractionChanged(step, changedRuler, newValue) {
        // we store the ruler value as a single float so we need to combine the slider value and the fraction combo value before emitting the update
        const whole = Fraction.split(changedRuler.current.value).whole;
        newValue = whole + newValue;

        emit('ruler-changed', step, changedRuler, newValue);
    }

    function onRulerChanged(step, changedRuler, newValue) {
        // we store the ruler value as a single float so we need to combine the slider value and the fraction combo value before emitting the update
        const decimal = Fraction.split(changedRuler.current.value).decimal;
        newValue = newValue + decimal;

        emit('ruler-changed', step, changedRuler, newValue);

        // if rulers are locked together, then send updates for each of the others too
        if (step.rulersLocked.value) {
            step.rulers.forEach((stepRuler) => {
                if (stepRuler.id != changedRuler.id) {
                    emit('ruler-changed', step, stepRuler, newValue);
                }
            });
        }
    }

    function toggleRulerAdjustmentHelper() {
        rulerAdjustmentHelper.value = !rulerAdjustmentHelper.value; // Toggle the value
    }

    function decimalToFraction(value) {
        const absoluteValue = Math.abs(value);

        const fractions = {
            0.125: '1/8',
            0.25: '1/4',
            0.375: '3/8',
            0.5: '1/2',
            0.625: '5/8',
            0.75: '3/4',
            0.875: '7/8',
        };
        const key = parseFloat(absoluteValue.toFixed(3)); // Round the absolute value to three decimal places
        const fraction = fractions[key] || absoluteValue; // Get the fraction or the absolute value if no fraction is matched

        return fraction;
    }

    const formatMeasurement = (value) => {
        const numeric = parseFloat(value); // Ensure value is a number
        if (!isNaN(numeric)) {
            const translatedDecimal = decimalToFraction(numeric);
            return translatedDecimal;
        }
        return value; // Return as is if conversion is not possible
    }

    onBeforeMount(() => {
        // if applicable, copy the current ruler adjustment
        if (props.step.selected.value.rulerAdjustment && props.step.selected.value.rulerAdjustment.range) {
            currentRulerAdjustment.value = props.step.selected.value.rulerAdjustment.current.value;
        }

        // watch for changes to the current ruler adjustment and emit a change event
        watch(currentRulerAdjustment, (newValue) => {
            emit('ruler-adjustment-changed', props.step, props.step.selected.value.rulerAdjustment, newValue);
        });
    });

</script>

<template>
        <div class="col-3 measurements__diagram">
            <Image :src="step.selected.value.image" />
        </div>
        <div class="col-8 measurements__rulers">
            <div class="row rulers measurements__inputs">
                <div class="measurements__tie">
                    <div class="tie__btn" @click="emit('toggle-ruler-lock', step)">
                        <!-- Locked? {{ step.rulersLocked.value }} -->
                        <img v-if="step.rulersLocked.value == true" class="icon icon--centered" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19e7_icon-locked-dk.svg" />
                        <img v-else class="icon icon--centered" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19ea_icon-unlocked-dk.svg" />
                    </div>
                    <div class="tie__top"></div>
                    <div class="tie__middle"></div>
                    <div class="tie__bottom"></div>
                </div>
                <div class="col">
                    <div v-for="(ruler, index) in step.rulers" :key="index" class="ruler__wrapper">
                        <div class="ruler__label">
                            <div class="ruler__label-text">{{ String.fromCharCode(65 + index) }}</div>
                        </div>
                        <Ruler :step="step" :ruler="ruler" @ruler-changed="onRulerChanged"></Ruler>        
                        <FractionSelect :step="step" :ruler="ruler" @fraction-changed="onFractionChanged"></FractionSelect>
                    </div>
                </div>
            </div>
            
            <div v-if="props.step.hasMeasureDiscrepency.value">One or more measurements that you entered is more than 1/2 inch different than the rest and may be incorrect. Please verify your measurements before continuing.</div>
            
            <div v-if="props.step.selected.value.rulerAdjustment">                
                <template v-if="props.step.selected.value.rulerAdjustment.range">
                    <div class="additional-text__wrapper">
                        <img @click="toggleRulerAdjustmentHelper" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19d3_icon-info.svg" width="14" data-everhem-helper="helperMountingHeight" alt="" class="additional-text-info is-left">
                        <div class="ruler__scale-text">{{ props.step.selected.value.rulerAdjustment.label }}</div>
                        <div class="margin-left-16">
                            <div class="select measure__select">
                                <select id="mountingHeight" name="mountingHeight" data-name="mountingHeight" class="select__input select__input--fraction select__input--mounting-height w-select"
                                    v-model="currentRulerAdjustment"
                                    :disabled="props.step.selected.value.rulerAdjustment.range.enabled.value ? null : 'disabled'"
                                >
                                        <option v-for="opt in props.step.selected.value.rulerAdjustment.range.options" :key="opt.value" :value="opt.value">{{ opt.text }}</option>
                                </select>
                                <label for="" class="select__label label--left">In<span class="hide--tablet"></span></label>                                
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="additional-text__wrapper width-deduction__wrapper">
                        <div class="ruler__additional-text is-italic">{{ props.step.selected.value.rulerAdjustment.label }}: </div>
                        <div class="ruler__additional-text is-result">{{ formatMeasurement(props.step.selected.value.rulerAdjustment.current.value) }} inch</div>
                        <img @click="toggleRulerAdjustmentHelper" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19d3_icon-info.svg" loading="lazy" width="14" data-everhem-helper="helperWindowDeduction" alt="" data-helper-ignore-closed="true" class="additional-text-info">
                    </div>
                </template>

                <div class="ruler__adjustment-container" v-if="rulerAdjustmentHelper">
                    <div class="ruler__adjustment-box">
                        <div>
                            <h5>{{ props.step.selected.value.rulerAdjustment.tip.title }}</h5>
                            <a class="modal--close" href="#" @click="rulerAdjustmentHelper = false"></a>
                        </div>
                        <div v-html="props.step.selected.value.rulerAdjustment.tip.html"></div>
                    </div>
                </div>
                
            </div>
        </div>
</template>