<script setup>
    import { ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import Rulers from '@/components/OptionLayouts/Rulers.vue';
    import BigCirclesLayout from '@/components/OptionLayouts/BigCircles.vue';    
    import CalculatedMeasurement from '@/components/OptionLayouts/CalculatedMeasurement.vue';
    import { useData } from '@/composables/Data';    
    import { usePriceCheck } from '@/composables/PriceCheck';
    import { useTracking } from '@/composables/Tracking';
    import Spinner from '@/components/Spinner.vue';
    import Fraction from '@/fraction.js';
        
    const router = useRouter();
    const route = useRoute();
    const data = useData();
    const track = useTracking();
    const priceCheck = usePriceCheck(data);
    const video = ref({
        shown: false,
        title: '',
        url: ''
    });

    const steps = Object.entries(data.steps).map((kv) => kv[1]).filter(step => { return step.category === 'measure'});

    function playVideo(title, id) {
        video.value.title = title;        
        video.value.url = `https://www.youtube.com/embed/${ id }?autoplay=1&rel=0&modestbranding=1`;
        video.value.shown = true;
    }

    function stopVideo() {
        video.value.title = '';
        video.value.url = '';
        video.value.shown = false;
    }

    function onSelectOption(step, selected) {
        step.selected.value = selected;             
    }

    function onToggleRulerLock(step) {
        step.rulersLocked.value = !step.rulersLocked.value;
    }

    function onRulerChanged(step, ruler, newValue) {
        ruler.current.value = newValue;
    }

    function onRulerAdjustmentChanged(step, rulerAdjustment, newValue) {
        rulerAdjustment.current.value = newValue;
    }

    function goToConfigurer() {
        router.push({ name: 'configure', params: route.params, query: route.query });
    }

    function goToConfirmation() {   
        track.goingToConfirmation(data.type, data.windowName.value, Object.entries(data.steps).map((kv) => kv[1]));
        router.push({ name: 'confirm', params: route.params, query: route.query });        
    }
</script>

<template>
    <div class="product-measure">
        <div class="frame__nav">
            <div class="config__nav-section">
                <a @click="goToConfigurer" class="config__back-link link-button link-button--light">← Cancel & Return to <span class="hide--tablet">{{ data.heading }}</span></a>
            </div>
            <div class="config__nav-section">
                <a href="/">
                    <img class="config__logo" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c1a14_logo-white-s.svg" />
                </a>
            </div>
            <div class="config__nav-section hide--mobile">
                <div class="config__title pretext pretext--light">Customizing: {{ data.heading }}</div>
            </div>
        </div>
        <div class="frame__body w-form">
            <div v-for="(step, index) in steps" :key="index" class="step">
                <div v-if="data.currentMeasureStep.value == index" class="step-contents">
                    <div class="header">
                        <div class="row product-title">
                            <div>
                                <h2>Step {{ index+1 }}</h2>  
                                <h1 class="step__num pretext">{{ step.title }}</h1>
                                <!-- {{ step }} -->
                            </div>
                            <div class="product-instructions">
                                <a v-if="step.selected.value.instructions?.yt_id" class="step__help-link" @click="playVideo(step.selected.value.instructions.title, step.selected.value.instructions.yt_id)">{{ step.selected.value.instructions?.title }}</a>
                                <p v-if="step.selected.value.measureGuide" class="rulers--measure-helper" v-html="step.selected.value.measureGuide"></p>
                            </div>
                        </div>
                        <div class="row step__desc">
                            <div class="step__desc-text color--lavaglas">
                                <div class="ts--callout" v-html="step.selected.value.html"></div>
                                <!-- @todo Clean up steps based on custom control -->
                                <div v-if="data.type === 'roman-shades' && step.id === 'cordPosition' || data.type === 'woven-woods' && step.id === 'cordPosition'">
                                    <div v-if="data.steps.customControl.selected.value.id === 'cordless'" class="ts--callout">
                                        <p>Cords are not visible, tassel is centered on the back of the shade at the bottom.</p>
                                    </div>
                                    <div v-else-if="data.steps.customControl.selected.value.id === 'motorization'" class="ts--callout">
                                        <p>Choose which side you will plug in your shade’s motor to recharge (about every 6 months.)</p>
                                    </div>
                                    
                                    <div v-if="data.steps.customControl.selected.value.id === 'chain-loop'" v-html="step.description" class="ts--callout"></div>
                                </div>
                                <div v-else-if="data.type === 'roman-shades' && step.id === 'windowWidth' || data.type === 'woven-woods' && step.id === 'windowWidth'">
                                    <div class="ts--callout" v-if="data.steps.customControl.selected.value.id === 'motorization' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75 || data.steps.customControl.selected.value.id === 'chain-loop' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75 || data.steps.customControl.selected.value.id === 'cordless' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75">
                                        <p>The maximum width for the mounting depth you selected is 72”. Need wider shades? Choose outside Mount Position, or enter a Mount Depth of at least 2¾ inches. Go back to re-customize.</p>
                                    </div>
                                </div>
                                <div v-else-if="data.type === 'roman-shades' && step.id === 'windowHeight' || data.type === 'woven-woods' && step.id === 'windowHeight'">
                                    <div class="ts--callout" v-if="data.steps.customControl.selected.value.id === 'motorization' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75 || data.steps.customControl.selected.value.id === 'chain-loop' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75">
                                        <p>The maximum height for the mounting depth you selected is 72”. Need taller shades? Choose outside Mount Position, or enter a Mount Depth of at least 2¾ inches. Go back to re-customize.</p>
                                    </div>
                                    <div class="ts--callout" v-else-if="data.steps.customControl.selected.value.id === 'cordless' && data.steps.mountPosition.selected.value.id === 'inside-mount' && data.steps.mountPosition.rulersMax.value < 2.75">
                                        <p>The maximum height for the mounting depth you selected is 90”. Need taller shades? Choose outside Mount Position, or enter a Mount Depth of at least 2¾ inches. Go back to re-customize.</p>
                                    </div>

                                </div>
                                <div v-else class="ts--callout" v-html="step.description"></div>
                            </div>
                            
                            <div class="total-width__content">
                                <div v-if="step.secondaryTitle" class="total__label pretext no-margin">{{ step.secondaryTitle }}</div>
                                <div v-else class="total__label pretext no-margin">{{ step.title }}</div>
                                <CalculatedMeasurement v-if="step.calculateMeasurement == true"
                                    :stepID="step.id"
                                    :packageType="data.steps.package.selected.value.id"
                                    :rulersMax="step.rulersMax"
                                    :windowWidth="data.steps.windowWidth.rulersMax"
                                    :windowHeight="data.steps.windowHeight.rulersMax"
                                    :stack="data.steps.stackWidth.rulersMax"
                                    :mountingHeight="data.steps.mountingHeight.rulersMax"
                                ></CalculatedMeasurement>
                                <div class="total__amount is--configurating total__shade-height" v-if="step.rulersMax && !step.calculateMeasurement">{{ Fraction.format.to(step.rulersMax.value) }} In</div>
                            </div>
                        </div>
                    </div>
                    <div class="step__body">
                        <div class="measurements" v-if="step.layout === 'rulers'">
                            {{ data.step }}
                            <Rulers :step="step" @toggle-ruler-lock="onToggleRulerLock" @ruler-changed="onRulerChanged" @ruler-adjustment-changed="onRulerAdjustmentChanged"></Rulers>
                        </div>                  
                        <div v-else-if="step.layout === 'big-circles'">
                            <BigCirclesLayout :step="step" @select-option="onSelectOption"></BigCirclesLayout>
                        </div>
                        <div v-else>
                            <span>Unknown step option layout</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="step--bottom">
                <div class="step-numbers">            
                    <template v-for="(step, index) in steps" :key="index">
                        <span v-if="index > 0"></span>
                        <a :class="{ 'active': data.currentMeasureStep.value === index }" @click="data.currentMeasureStep.value=index">{{ index+1 }}</a>
                    </template>
                </div>
            
                <div class="buttons">
                    <div class="total">
                        <span>Estimated<br>Total</span>
                        <!-- <div>{{ priceCheck }}</div> -->
                        <div>
                            <template v-if="priceCheck.price.value == priceCheck.salePrice.value && priceCheck.isCalculating.value == false">{{ $formatCurrency(priceCheck.salePrice.value) }}</template>
                            <template v-else-if="priceCheck.price.value >= priceCheck.salePrice.value && priceCheck.isCalculating.value == false"><span class="pricing--strikeout">{{ $formatCurrency(priceCheck.price.value) }}</span> {{ $formatCurrency(priceCheck.salePrice.value) }}</template>
                            <Spinner v-else></Spinner>
                        </div>
                    </div>    
                    <BButton class="step__back-btn w-button" v-if="data.currentMeasureStep.value == 0" @click="goToConfigurer">← Back: Design & Function</BButton>
                    <BButton class="step__back-btn w-button" v-if="data.currentMeasureStep.value > 0" @click="data.currentMeasureStep.value--">← Back: {{ steps[data.currentMeasureStep.value-1].name }}</BButton>            
                    <BButton class="step__next-btn w-button" v-if="data.currentMeasureStep.value < steps.length-1" @click="data.currentMeasureStep.value++">Next: {{ steps[data.currentMeasureStep.value+1].name }} →</BButton>
                    <BButton class="step__next-btn w-button" v-else @click="goToConfirmation">Confirm Details →</BButton>
                </div>
            </div>
        </div>                   
    </div>
    <div class="modal--video modal modal--vert" :style="video.shown ? 'display: flex' : 'display: none'">
        <div data-w-id="c56e8dff-c72a-b4a6-aac2-62be7ae06151" class="modal__overlay video-modal__close-overlay"></div>
        <div class="modal__header modal__header--l modal__header--light modal__header--outside">
            <div class="section__header-col video-modal__title">
                <div class="pretext color--chantilly">Watch &amp;&nbsp;Learn</div>
                <div class="video-title color--white no--margin-b h2">{{ video.title }}</div>
            </div>
            <div class="section__header-col section__header-col--shrink">
                <a @click="stopVideo()" class="video-modal__close-button link color--white">✕ Close</a>
            </div>
        </div>
        <!-- style="transform: translate3d(0px, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;" -->
        <div class="modal__wrapper modal--wrapper--l">
            <div class="modal__body modal__body--video">
                <div class="w-embed w-iframe">
                    <div class="embed-container">
                        <iframe class="video-iframe" :src="video.url" frameborder="0" allowfullscreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="video--trigger"></div>
    </div>
</template>